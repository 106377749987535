<template>
  <BittsDrawer
    :visible="showDrawer"
    :mask-closable="true"
    :show-footer="true"
    :info="{ title: 'Integration Settings' }"
    wrapper-class="c-oauth-drawer"
    :use-custom-icon="true"
    @closed="closeDrawerHandler"
  >
    <template #customIcon>
      <FontAwesomeIcon
        :icon="['fad', 'cloud-word']"
        :style="{ height: '40px', width: '40px', color: 'currentColor' }"
        class="mr-16 text-neutral-accent"
      />
    </template>
    <template #content>
      <div class="m-24">
        <BittsInput
          v-model="oAuthApplication.name"
          form-label="Integration Name"
          placeholder="Integration Name"
          name="integration-name"
          :status="
            v$.oAuthApplication.name.$errors.length ? 'danger' : 'default'
          "
          :danger-text="
            v$.oAuthApplication.name.$errors?.at(-1)?.$message || ''
          "
          class="mb-24"
        />
        <div class="mb-24">
          <BittsTextArea
            v-model="oAuthApplication.description"
            form-label="Integration Description"
            placeholder="Integration Description"
          />
        </div>
        <div class="mb-8 text-neutral-text-strong font-bold text-sm">
          Callback URLs
        </div>
        <BittsSelectTags
          v-model="oAuthApplication.callbacks"
          :warning-message="showCallbackUrlsMessage ? callbackUrlsMessage : ''"
          note="Note: You can add separate callback URLs by separating with enter"
          placeholder="Callback URLs"
          class="mb-24"
          @change="onChangeCallbackUrls"
        />
        <div class="mb-8 text-neutral-text-strong font-bold text-sm">
          Allowed Origins
        </div>
        <BittsSelectTags
          v-model="oAuthApplication.allowed_origins"
          note="Note: You can add separate allowed origins by separating with enter"
          :warning-message="
            showAllowedOriginsMessage ? allowedOriginsMessage : ''
          "
          placeholder="Allowed Origins"
          class="mb-24"
          @change="onChangeAllowedOrigins"
        />
        <BittsInput
          v-model.trim="oAuthApplication.client_id"
          :allow-copy="true"
          :read-only="true"
          name="integration-client-id"
          form-label="Client ID"
          placeholder="Client ID"
          class="mb-16"
        />
        <BittsInput
          v-model.trim="oAuthApplication.client_secret"
          type="password"
          :read-only="true"
          :allow-copy="true"
          name="integration-client-id"
          form-label="Client Secret"
          placeholder="Client Secret"
          class="mb-16"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex justify-center align-center gap-16">
        <BittsButton
          text="Delete Integration"
          type="danger"
          class="w-1/2"
          @click="deleteApplication"
        />
        <BittsButton
          :loading="pageLoading || !ready"
          :disabled="!isEditMode"
          text="Save Changes"
          type="primary"
          class="w-1/2"
          @click="editApplication"
        />
      </div>
    </template>
  </BittsDrawer>
</template>

<script>
import {
  BittsButton,
  BittsDrawer,
  BittsInput,
  BittsSelectTags,
  BittsTextArea,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import axios from 'axios';
import { cloneDeep, isEqual } from 'lodash';
import { mapActions, mapState } from 'pinia';
import { computed, ref } from 'vue';

import { captureException } from '@/errors';
import { useFlashesStore, useOauthApplicationStore } from '@/stores';
import urls from '@/urls';
import { areAllCallbacksValid, areAllOriginsValid, isUrlValid } from '@/utils';

export default {
  name: 'OAuthSettingsDrawer',
  components: {
    BittsButton,
    BittsDrawer,
    BittsInput,
    BittsTextArea,
    BittsSelectTags,
  },

  setup() {
    const oAuthApplication = ref({});

    useHead({
      title: computed(() =>
        oAuthApplication.value?.name
          ? `Integrations - ${oAuthApplication.value.name} - Crossbeam`
          : 'Integrations - Crossbeam',
      ),
    });

    const rules = {
      oAuthApplication: {
        name: {
          required: helpers.withMessage(
            'Please name your application.',
            required,
          ),
        },
        callbacks: { areAllCallbacksValid },
        allowed_origins: { areAllOriginsValid },
      },
    };

    const v$ = useVuelidate(rules, { oAuthApplication });

    return { oAuthApplication, v$ };
  },
  data() {
    return {
      showDrawer: true,
      pageLoading: false,
      showClientSecret: false,
      originalApplication: {},
      showAllowedOriginsMessage: false,
      showCallbackUrlsMessage: false,
    };
  },
  computed: {
    ...mapState(useOauthApplicationStore, ['getOAuthApplicationById', 'ready']),
    isEditMode() {
      return !isEqual(this.originalApplication, this.oAuthApplication);
    },
    secretIconType() {
      return this.showClientSecret ? 'eye-slash' : 'eye';
    },

    callbackUrlsMessage() {
      return 'Callback must start with https://';
    },
    allowedOriginsMessage() {
      return 'Origin must start with https:// or chrome-extensions://';
    },
  },
  created() {
    this.oAuthApplication = this.getOAuthApplicationById(this.$route.params.id);
    this.originalApplication = cloneDeep(this.oAuthApplication);
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useOauthApplicationStore, [
      'refreshOAuthApplicationsStore',
      'deleteOAuthApplication',
    ]),
    onChangeAllowedOrigins(val) {
      this.showAllowedOriginsMessage = !!val.filter(
        (v) => !this.isOriginValid(v),
      ).length;
      this.oAuthApplication.allowed_origins = val.filter((v) =>
        this.isOriginValid(v),
      );
    },
    onChangeCallbackUrls(val) {
      this.showCallbackUrlsMessage = !!val.filter(
        (v) => !this.isCallbackValid(v),
      ).length;
      this.oAuthApplication.callbacks = val.filter((v) =>
        this.isCallbackValid(v),
      );
    },
    toggleShowSecret() {
      this.showClientSecret = !this.showClientSecret;
    },
    isOriginValid(tag) {
      return this.isUrlValid(tag, ['chrome-extensions://']);
    },
    isCallbackValid(tag) {
      return this.isUrlValid(tag, []);
    },
    async editApplication() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      const payload = {
        name: this.oAuthApplication.name,
        description: this.oAuthApplication.description,
        callbacks: this.oAuthApplication.callbacks,
        allowed_origins: this.oAuthApplication.allowed_origins,
      };

      if (this.isEditMode) {
        this.pageLoading = true;
        try {
          await axios.patch(
            urls.oAuthApplications.patch(this.oAuthApplication.id),
            payload,
          );
        } catch (err) {
          captureException(err);
        } finally {
          this.pageLoading = false;
          this.closeDrawerHandler();
          await this.refreshOAuthApplicationsStore();
        }
      }
    },
    async deleteApplication() {
      this.pageLoading = true;
      try {
        await this.deleteOAuthApplication(this.oAuthApplication.id);
        this.addSuccessFlash({ message: 'Integration deleted' });
      } catch (_err) {
        this.addErrorFlash({
          message: 'Something went wrong',
          description: 'Integration not deleted',
        });
      } finally {
        this.pageLoading = false;
        this.closeDrawerHandler();
        await this.refreshOAuthApplicationsStore();
      }
    },
    isUrlValid(tag, allowedProtocols) {
      return isUrlValid(tag, allowedProtocols);
    },
    async closeDrawerHandler() {
      this.showDrawer = false;
      await this.$router.push({
        name: 'integrations',
        query: {
          ...this.$route.query,
        },
      });
    },
  },
};
</script>
<style lang="pcss">
.c-oauth-drawer header {
  padding: 20px 24px !important;
}
.c-oauth-drawer .pc-integrations-modal__close-button svg {
  color: theme(colors.neutral.text) !important;
}

.c-oauth-drawer .c-bitts-drawer__subtitle-border {
  @apply hidden;
}

.c-oauth-drawer .bitts-drawer__header-title {
  @apply text-neutral-text-strong text-xl font-bold;
}
</style>
