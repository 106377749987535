<template>
  <BittsLoading :is-loading="loading">
    <section class="c-frigg-form-builder">
      <div
        v-for="input in formSetup"
        :key="input.id"
        class="c-frigg-form-builder__input"
      >
        <label
          v-if="hideLabel(input.inputType) !== true"
          :for="input.id"
          class="c-frigg-form-builder__input-label"
          >{{ labelText(input.required, input.label) }}
          <BittsTooltip
            v-if="input.helperText"
            :mount-to-body="true"
            placement="top"
          >
            <FontAwesomeIcon
              :icon="['fas', 'info-circle']"
              :style="{ height: '12px', width: '12px', color: 'currentColor' }"
              class="text-neutral-300 ml-4"
            />
            <template #title>
              {{ input.helperText }}
            </template>
          </BittsTooltip>
        </label>
        <div
          v-if="input.inputType === 'mapping'"
          class="c-frigg-form-builder__data-mapping"
        >
          <div class="flex justify-between mb-10 text-sm text-neutral-600">
            <div class="flex items-center w-1/2">
              <BittsAvatar
                :org="targetOrg('source')"
                :show-border="false"
                :rounded="false"
                class="mr-8 rounded-none"
                shape="square"
                size="tiny"
              />
              {{ input.source.label }}
            </div>
            <div class="flex items-center w-1/2 mr-bts5">
              <BittsAvatar
                :org="targetOrg(targetIsInCrossbeam ? 'source' : 'target')"
                :show-border="false"
                :rounded="false"
                shape="square"
                class="mr-8"
                size="tiny"
              />
              {{ input.target.label }}
            </div>
          </div>
          <!-- source --- crossbeam data -->
          <div
            v-for="(mapId, idx) in dataMaps[input.key].mappingIds"
            :key="mapId"
            class="wrapper"
          >
            <div class="data-mapping--source w-1/2">
              <BittsInputSelect
                v-if="dataMaps[input.key].source.inputType === 'select'"
                :ref="mapId"
                :show-checkbox="dataMaps[input.key].source.multi"
                :searchable="true"
                :multiple="dataMaps[input.key].source.multi"
                :hide-clear="true"
                :hide-selected="true"
                :initial-single-option="
                  fillOptionByIndex(idx, input.key, 'source')
                "
                :options="dataMaps[input.key].options"
                :close-on-select="!dataMaps[input.key].source.multi"
                :placeholder="
                  dataMaps[input.key].source.placeholder ||
                  dataMaps[input.key].source.label
                "
                no-options-text="No options left"
                track-by="name"
                label="name"
                @selected-options-change="
                  (option) =>
                    changeFriggMapOption(option, idx, input.key, 'source')
                "
              />
            </div>
            <!-- target --- integration data -->
            <div class="data-mapping--target w-1/2">
              <BittsInputSelect
                v-if="dataMaps[input.key].target.inputType === 'select'"
                :ref="mapId"
                :show-checkbox="dataMaps[input.key].target.multi"
                :searchable="true"
                :hide-clear="true"
                :hide-selected="true"
                :initial-single-option="
                  fillOptionByIndex(idx, input.key, 'target')
                "
                :multiple="dataMaps[input.key].target.multi"
                :options="dataMaps[input.key].targetOptions"
                :close-on-select="!dataMaps[input.key].target.multi"
                :placeholder="
                  dataMaps[input.key].target.placeholder ||
                  dataMaps[input.key].target.label
                "
                no-options-text="No options left"
                track-by="name"
                label="name"
                @selected-options-change="
                  (option) =>
                    changeFriggMapOption(option, idx, input.key, 'target')
                "
              />
            </div>
            <div
              class="data-mapping--remove"
              @click="onDeleteDataMap(input, idx, mapId)"
            >
              <FontAwesomeIcon
                :icon="['fas', 'trash-can']"
                :style="{
                  height: '12px',
                  width: '12px',
                  color: 'currentColor',
                }"
                class="text-neutral-400 border-none"
              />
            </div>
          </div>
          <BittsButton
            :icon-left="['fas', 'plus']"
            text="Add New Rule"
            type="neutral"
            variant="outline"
            size="small"
            class="w-full mt-16"
            data-testid="frigg-add-rule-button"
            @click="onAddDataMap(input)"
          />
        </div>
        <VuelidateWrapper
          :errors="v$.$errors"
          :property="input.key"
          error-class="leading-4"
        >
          <BittsInput
            v-if="input.inputType === 'input'"
            :id="input.id"
            v-model="formValues[input.key]"
            :name="`input__${input.id}`"
            :placeholder="input.placeholder || input.label"
          />
          <BittsInputSelect
            v-if="input.inputType === 'select'"
            :show-checkbox="input.multi"
            :searchable="true"
            :multiple="input.multi"
            :options="addNameToOption(input.options, input.key)"
            :close-on-select="!input.multi"
            :initial-selected-options="
              input.multi ? formValues[input.key] : null
            "
            :initial-single-option="input.multi ? null : formValues[input.key]"
            :placeholder="input.placeholder || input.label"
            track-by="name"
            label="name"
            class="my-8"
            @selected-options-change="
              (option) => changeFriggOption(option, input.key)
            "
            @clear="handleClearInput(input.key)"
          />
          <BittsSwitch
            v-if="input.inputType === 'boolean'"
            v-model="formValues[input.key]"
            :label="input.label"
            @change="
              (option) =>
                changeFriggBooleanOption(option, input.key, input.required)
            "
          >
            <template #label>
              <div class="inline-flex">
                <span class="text-sm text-neutral-600 flex items-center">
                  {{ labelText(input.required, input.label) }}</span
                >
                <BittsTooltip
                  v-if="input.helperText"
                  :mount-to-body="true"
                  placement="top"
                >
                  <FontAwesomeIcon
                    :icon="['fas', 'info-circle']"
                    :style="{
                      height: '12px',
                      width: '12px',
                      color: 'currentColor',
                    }"
                    class="text-neutral-300 ml-4"
                  />
                  <template #title>
                    {{ input.helperText }}
                  </template>
                </BittsTooltip>
              </div>
            </template>
          </BittsSwitch>
          <BittsCheckboxCard
            v-if="input.inputType === 'checkbox'"
            :label="labelText(input.required, input.label)"
            :default-checked="formValues[input.key]"
            :description="input.description"
            :clickable-wrapper="!hasSomeHtml(input.description)"
            @input="
              (option) =>
                changeFriggBooleanOption(option, input.key, input.required)
            "
          />
          <p
            v-if="input.description && input.inputType !== 'checkbox'"
            class="c-frigg-form-builder__input-description"
            v-html="input.description"
          />
        </VuelidateWrapper>
      </div>
    </section>
  </BittsLoading>
</template>

<script>
import {
  BittsAvatar,
  BittsButton,
  BittsCheckboxCard,
  BittsInput,
  BittsInputSelect,
  BittsLoading,
  BittsSwitch,
  BittsTooltip,
} from '@crossbeam/bitts';

import { useVuelidate } from '@vuelidate/core';
import {
  alpha,
  alphaNum,
  email,
  helpers,
  maxLength,
  minLength,
  numeric,
  required,
  url,
} from '@vuelidate/validators';
import { isArray } from 'lodash';
import { mapActions, mapState } from 'pinia';
import { computed, ref } from 'vue';

import VuelidateWrapper from '@/components/VuelidateWrapper.vue';

import {
  useFlashesStore,
  usePopulationsStore,
  useReportsStore,
  useTeamStore,
} from '@/stores';
import { uuid4 } from '@/utils';

export default {
  name: 'FriggFormBuilder',
  components: {
    BittsAvatar,
    BittsButton,
    BittsInputSelect,
    BittsInput,
    BittsLoading,
    BittsSwitch,
    BittsTooltip,
    BittsCheckboxCard,
    VuelidateWrapper,
  },
  props: {
    friggDetails: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
    configResponse: {
      type: Array,
      default: () => [],
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['saved'],
  setup() {
    const maxOrMinMessage = (minLength, maxLength) => {
      if (minLength && !maxLength) {
        return `Minimum of ${minLength} characters needed`;
      }
      if (maxLength && !minLength) {
        return `Can have a maximum of ${maxLength} characters`;
      }
      if (maxLength && minLength) {
        if (maxLength === minLength) {
          return `Must be exactly ${maxLength} characters`;
        }
        return `Must be between ${minLength} characters
          and ${maxLength} characters`;
      }
      return null;
    };
    const mappingValid = (sourceAndTargetArray) => {
      for (const { source, target } of sourceAndTargetArray) {
        if (
          Object.keys(source).length === 0 ||
          Object.keys(target).length === 0
        ) {
          return false;
        }
      }
      return true;
    };

    // formValues is what we will send along in payload
    // formSetup is the array of objects telling us what each input should be
    const formValues = ref({});
    const formSetup = ref([]);

    const formValueRule = computed(() => {
      return formSetup.value.reduce((acc, current) => {
        const rulesObj = {};
        if (current.required && current.inputType !== 'mapping') {
          rulesObj.required = helpers.withMessage(
            'This field is required',
            required,
          );
        }
        if (current.inputType === 'mapping') {
          rulesObj.mappingValid = helpers.withMessage(
            `All rules should have a ${current.source.label} and ${current.target.label} selected`,
            mappingValid,
          );
        }
        const { rules } = current;
        if (rules) {
          const rulesMap = {
            email: helpers.withMessage('Please enter a valid email', email),
            url: helpers.withMessage('Please enter a valid url', url),
            numeric: helpers.withMessage(
              'Please enter a valid number',
              numeric,
            ),
            alpha: helpers.withMessage('Only letters are allowed', alpha),
            alphaNum: helpers.withMessage(
              'Must be alphanumeric characters',
              alphaNum,
            ),
            maxLength:
              rules.maxLength &&
              helpers.withMessage(
                maxOrMinMessage(rules.minLength, rules.maxLength),
                maxLength(rules.maxLength),
              ),
            minLength:
              rules.minLength &&
              helpers.withMessage(
                maxOrMinMessage(rules.minLength, rules.maxLength),
                minLength(rules.minLength),
              ),
            regex:
              rules.regex?.rule &&
              helpers.withMessage(rules.regex?.message, (val) => {
                const reg = new RegExp(rules.regex.rule);
                return reg.test(val);
              }),
          };
          Object.keys(rules).forEach((ruleType) => {
            rulesObj[ruleType] = rulesMap[ruleType];
          });
        }
        acc[current.key] = rulesObj;
        return acc;
      }, {});
    });

    const vuelidateRules = { formValues: formValueRule };

    const v$ = useVuelidate(vuelidateRules, { formValues }, { $lazy: true });

    return { formValues, formSetup, v$ };
  },
  data() {
    return {
      // display is simple name, description and url for the modal
      // friggDetails is the full integration merged with entity from /api/integrations
      fieldPayloadSpecs: {},
      loading: false,
      dataMaps: {},
      targetIsInCrossbeam: false,
    };
  },
  computed: {
    ...mapState(useReportsStore, ['reports']),
    ...mapState(usePopulationsStore, ['populations']),
    ...mapState(useTeamStore, ['authorizations']),
    enabled() {
      return this.friggDetails.status === 'ENABLED';
    },
    crossbeamDataSources() {
      return {
        populations: this.populations,
        reports: this.reports,
        users: this.authorizations.map((auth) => {
          const user = auth.user;
          return {
            id: user.id,
            name: [user.first_name, user.last_name].join(' '),
            first_name: user.first_name,
            last_name: user.last_name,
          };
        }),
      };
    },
  },
  watch: {
    saving() {
      if (this.saving) {
        this.onSave();
      }
    },
  },
  created() {
    this.loading = true;
    try {
      this.formSetup = this.configResponse;
      // Setup formValues
      this.formSetup.forEach((input) => {
        // Building up object to reference when building payload on save / add
        if (input.properties) {
          if (input.inputType === 'mapping') {
            this.fieldPayloadSpecs[input.key] = {
              mapping: true,
              payloadStructure: input.properties,
              sourceSpecs: input.source.properties,
              targetSpecs: input.target.properties,
            };
          } else {
            this.fieldPayloadSpecs[input.key] = {
              properties: input.properties,
              multi: input.multi,
            };
          }
        } else {
          this.fieldPayloadSpecs[input.key] = input.type;
        }
        // If populations or reports then assign crossbeam data to options
        // If Frigg is asking for populations or reports, those will be the keys
        if (this.crossbeamDataSources[input.key]) {
          input.options = this.crossbeamDataSources[input.key];
        }
        if (input.inputType === 'mapping') {
          this.setupTargetsAndSources(input);
        } else {
          this.formValues[input.key] = this.transformOptions(input);
        }
      });
    } catch (_err) {
      this.addErrorFlash({ message: 'Integration configuration not found' });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(useFlashesStore, ['addErrorFlash']),
    fillOptionByIndex(idx, inputKey, sourceOrTarget) {
      if (!this.dataMaps[inputKey].prefilledValues[idx]) return;
      return sourceOrTarget === 'source'
        ? this.dataMaps[inputKey].prefilledValues[idx].source
        : this.dataMaps[inputKey].prefilledValues[idx].target;
    },
    setupTargetsAndSources(input) {
      let options;

      if (this.crossbeamDataSources[input.source.key]) {
        options = this.crossbeamDataSources[input.source.key];
      }

      let targetOptions;
      if (this.crossbeamDataSources[input.target.key]) {
        this.targetIsInCrossbeam = true;
        targetOptions = this.crossbeamDataSources[input.target.key];
      } else {
        targetOptions = this.addNameToOption(input.target.options);
      }

      const prefilledValues = this.transformIncomingMappings(input);
      const prefilledSourcesLookup = {};
      const prefilledTargetsLookup = {};
      let remainingOptions = [];
      let remainingTargets = [];
      // building up the prefilled lookups
      if (input.source.unique || input.target.unique) {
        prefilledValues.forEach(({ source, target }) => {
          prefilledSourcesLookup[source.id] = source;
          prefilledTargetsLookup[target.value] = target;
        });
        // If the input is unique, as in you are only allowed to select one of each
        // option, then we filter out the incoming prefilled values
        if (input.source.unique) {
          remainingOptions = options.filter(
            (option) => !prefilledSourcesLookup[option.id],
          );
        }
        if (input.target.unique) {
          remainingTargets = targetOptions.filter(
            (option) => !prefilledTargetsLookup[option.value],
          );
        }
      }

      // using uuid function to create on the fly id's for mapping rows
      const mappingIds = prefilledValues.map(() => uuid4());
      if (!mappingIds.length) mappingIds.push(uuid4());
      const finalPrefilledValues = prefilledValues.length
        ? [...prefilledValues]
        : [{ source: {}, target: {} }];

      this.dataMaps[input.key] = {
        mappingIds,
        prefilledValues,
        availableSourceOptions: options,
        availableTargetOptions: targetOptions,
        options: input.source.unique ? remainingOptions : options,
        targetOptions: input.target.unique ? remainingTargets : targetOptions,
        target: input.target,
        source: input.source,
      };
      this.formValues[input.key] = finalPrefilledValues;
    },
    transformIncomingMappings(mappingConfig) {
      /*
        This function converts the structure of existing values for mapping configurations
        from the "Frigg way" to the "UI way" so we can prefill the form with existing values.

        We use an array of objects for dataMappings in the UI. Each dataMapping
        object has a target object and source object, like so:

        [{
          source: {
            id:  "031a4493-18ba-4ae0-bcb7-12a5ba90b382",
            name: "New Report",
            organization_id:14
          },
          target: {
            name: Andy123,
            value: zdgdka938283
          }
         }]

        The Frigg way is an object of objects, (`incomingValue` here) for example:

        {
          "031a4493-18ba-4ae0-bcb7-12a5ba90b382": {
            "name": "New Report",
            "rollWorksUserId": "zdgdka938283",
            "rollWorksUsername": "Andy123"
          },
          ...
        }

        This process is similar to the transformMappingPayloadValue method used when
        building payloads but does the reverse: converting the "Frigg way"
        to the "UI way" instead of the "UI way" to the "Frigg way"

        In both cases, the config passed to us from frigg (`mappingConfig` here)
        is used as the key to the conversion.
        The `properties` (or `payloadStructure` here) specify
        the structure of what frigg is sending us (and expecting to receive):

        An example `payloadStructure`:
        'source.id': {
          type: 'Object',
          properties: {
            name: 'source.name',
            rollWorksUserId: 'target.rollWorksUserId',
            rollWorksUsername: 'target.rollWorksUsername'
          }
        }

        Additionally, `mappingConfig` specifies properties for both the target and source.
        In the above, note how `rollWorksUserId` is asking for `target.rollWorksUserId`.
        In `target.properties` of this config object we would have:

        properties: {
          rollWorksUserId: 'option.value',
          rollWorksUsername: 'option.label'
        }

        So now we know that `rollWorksUserId` in the "Frigg way" should become
       `target.value` in the "UI way" object we are building up.
      */
      const incomingValue = this.friggDetails.config[mappingConfig.key];
      if (!incomingValue) return [];
      const { properties: payloadStructure } = mappingConfig;
      const [primaryEntityAndKey, { properties: payloadProps }] =
        Object.entries(payloadStructure)[0];
      // The primaryEntity is likely 'source',
      // meaning the 'source' will have the primary key for the entities.
      // The `primaryKeyOfEntity` will probably be 'id', which
      // corresponds to a report or population uuid
      const [primaryEntity, primaryKeyOfEntity] =
        primaryEntityAndKey.split('.');

      const result = Object.entries(incomingValue).reduce(
        (finalSourcesAndTargets, currentSourceAndTarget) => {
          const [primaryKeyLabel, incomingValues] = currentSourceAndTarget;
          const tempTargetAndSourceObj = {
            target: {},
            source: {},
          };
          Object.entries(payloadProps).forEach(([propId, propValue]) => {
            const [sourceOrTarget, sourceOrTargetKey] = propValue.split('.');
            /*
              sourceOrTarget is as it reads, either 'source' or 'target'
              sourceOrTargetKey is what we need to look up in the target.properties or
              source.properties this would be something like rollWorksUserId
             */

            const [, wantedProp] =
              mappingConfig[sourceOrTarget].properties[sourceOrTargetKey].split(
                '.',
              );

            tempTargetAndSourceObj[sourceOrTarget][wantedProp] =
              incomingValues[propId];
            /*
              if the wantedProp is label, we know we have to map that value to name
              This reduces a loop later on if done here. As mentioned in other places in this file,
              we have to map a redundant name to the object because label
              doesn't play well with Vue Multiselect
            */
            if (sourceOrTarget === 'target' && wantedProp === 'label') {
              tempTargetAndSourceObj[sourceOrTarget].name =
                incomingValues[propId];
            }
          });
          tempTargetAndSourceObj[primaryEntity][primaryKeyOfEntity] =
            primaryKeyLabel;
          finalSourcesAndTargets.push(tempTargetAndSourceObj);
          return finalSourcesAndTargets;
        },
        [],
      );
      if (
        this.crossbeamDataSources[mappingConfig.source.key] &&
        this.crossbeamDataSources[mappingConfig.target.key]
      ) {
        return this.filteredSourcesAndTargets2(
          result,
          mappingConfig.source.key,
          mappingConfig.target.key,
        );
      } else if (this.crossbeamDataSources[mappingConfig.source.key]) {
        return this.filteredSourcesAndTargets(
          result,
          mappingConfig.source.key,
          mappingConfig.target.options,
        );
      }
      return result;
    },
    transformMappingPayloadValue(originalValue, setup) {
      const { sourceSpecs, targetSpecs, payloadStructure } = setup;
      /*
        use payloadStructure as reference to structure payload
        We split the references below so we can dynamically build the expected payload
        Example shape:
        'source.id': {
          type: 'Object',
          properties: {
            name: 'source.name',
            rollWorksUserId: 'target.rollWorksUserId',
            rollWorksUsername: 'target.rollWorksUsername'
          }
        }
      */
      const [primarySourceOrTarget, { properties: payloadProps }] =
        Object.entries(payloadStructure)[0];
      const [primaryEntity, primaryKeyOfEntity] =
        primarySourceOrTarget.split('.');
      // In the example above: primaryEntity is 'source' and primaryKeyOfEntity is 'id'
      return originalValue.reduce((finalPayload, current) => {
        const combinedTargetAndSource = {};
        Object.entries(payloadProps).forEach(([propKey, propToLookup]) => {
          const [propToLookupKey, propToLookupVal] = propToLookup.split('.');
          const [, wantedProp] =
            propToLookupKey === 'target'
              ? targetSpecs[propToLookupVal].split('.')
              : sourceSpecs[propToLookupVal].split('.');
          combinedTargetAndSource[propKey] =
            current[propToLookupKey][wantedProp];
        });
        finalPayload[current[primaryEntity][primaryKeyOfEntity]] =
          combinedTargetAndSource;
        return finalPayload;
      }, {});
    },
    targetOrg(sourceOrTarget) {
      if (sourceOrTarget === 'source') {
        return {
          logo_url: new URL(
            '../../assets/pngs/partner-cloud/Crossbeam.png',
            import.meta.url,
          ).href,
        };
      }
      return this.friggDetails.avatar
        ? {
            logo_url: new URL(
              `../../assets/pngs/partner-cloud/${this.friggDetails.avatar}`,
              import.meta.url,
            ).href,
          }
        : { clearbit_domain: this.friggDetails.display.icon };
    },
    onAddDataMap(input) {
      if (this.dataMaps[input.key].mappingIds.length === 0) return;
      const { length: rowCount } = this.dataMaps[input.key].mappingIds;
      const { length: sourceCount } =
        this.dataMaps[input.key].availableSourceOptions;
      const { length: targetCount } =
        this.dataMaps[input.key].availableTargetOptions;
      const minRowCount = Math.min(sourceCount, targetCount);
      /*
        Limit the number of rows to available options:
        1: both target and source are unique
        2: source is unique but not target
        3: target is unique but not source
      */
      if (input.source.unique && input.target.unique) {
        if (rowCount === minRowCount) return;
      }
      if (input.source.unique && !input.target.unique) {
        if (rowCount === sourceCount) return;
      }
      if (input.target.unique && !input.source.unique) {
        if (rowCount === targetCount) return;
      }
      this.dataMaps[input.key].mappingIds.push(uuid4());
      this.formValues[input.key].push({
        source: {},
        target: {},
      });
      // cloning dataMaps to trigger reactivity
      this.dataMaps = { ...this.dataMaps };
    },
    onDeleteDataMap(input, idx, id) {
      /*
        deleting a data map is the same as deleting a "row" in the UI. If the source
        or target is to be unique - which means that it can only have as many fields
        as there are available options, we need to delete and add back to available
        options as needed.
      */
      const deletedSourceOption = this.formValues[input.key][idx].source;
      const deletedTargetOption = this.formValues[input.key][idx].target;
      if (this.dataMaps[input.key].prefilledValues[idx]) {
        this.dataMaps[input.key].prefilledValues.splice(idx, 1);
      }
      if (idx === 0 && this.dataMaps[input.key].mappingIds.length === 1) {
        this.$refs[id][0].clear();
        this.$refs[id][1].clear();
        this.formValues[input.key][idx].source = {};
        this.formValues[input.key][idx].target = {};
        this.dataMaps[input.key].options =
          this.dataMaps[input.key].availableSourceOptions;
        this.dataMaps[input.key].targetOptions =
          this.dataMaps[input.key].availableTargetOptions;
      }
      if (this.dataMaps[input.key].mappingIds.length > 1) {
        this.formValues[input.key].splice(idx, 1);
        this.dataMaps[input.key].mappingIds.splice(idx, 1);
        if (input.source.unique) {
          const sourceOptionExists = !!this.dataMaps[input.key].options.find(
            (option) => option.id === deletedSourceOption.id,
          );
          if (!sourceOptionExists && deletedSourceOption.id) {
            this.dataMaps[input.key].options.push({ ...deletedSourceOption });
          }
        }
        if (input.target.unique) {
          const targetOptionExists = !!this.dataMaps[
            input.key
          ].targetOptions.find(
            (option) => option.name === deletedSourceOption.name,
          );
          if (!targetOptionExists && deletedTargetOption.name) {
            this.dataMaps[input.key].targetOptions.push({
              ...deletedTargetOption,
            });
          }
        }
      }
      this.dataMaps = { ...this.dataMaps };
    },
    changeFriggMapOption(selected, idx, inputKey, sourceOrTargetKey) {
      const fromSource = sourceOrTargetKey === 'source';
      const fromTarget = sourceOrTargetKey === 'target';
      if (
        (fromSource && this.dataMaps[inputKey].source.unique) ||
        (fromTarget && this.dataMaps[inputKey].target.unique)
      ) {
        const currentOption = this.formValues[inputKey][idx][sourceOrTargetKey];
        // Filter out the selected option from the options available

        // adjust source options if fromSource
        if (fromSource) {
          this.dataMaps[inputKey].options = this.dataMaps[
            inputKey
          ].options.filter((option) => option.id !== selected.id);
          // Put current option back if not the first selection
          if (Object.keys(currentOption).length > 0) {
            this.dataMaps[inputKey].options.push(currentOption);
          }
        } else {
          this.dataMaps[inputKey].targetOptions = this.dataMaps[
            inputKey
          ].targetOptions.filter((option) => option.value !== selected.value);
          // Put current option back if not the first selection
          if (Object.keys(currentOption).length > 0) {
            this.dataMaps[inputKey].targetOptions.push(currentOption);
          }
        }
      }
      this.dataMaps = { ...this.dataMaps };
      this.formValues[inputKey][idx][sourceOrTargetKey] = isArray(selected)
        ? {}
        : selected;
    },
    transformOptions(input) {
      // This function is to set the value for formValues which we use to
      // send along payload of form data.
      // If the input is a select field we have to transform objects by
      // assigning a name property. If it's a boolean, we need to set to the
      // value coming in, otherwise we want to default it to false instead of null.
      // For everything else we set to null.
      if (
        Object.prototype.hasOwnProperty.call(
          this.friggDetails.config,
          input.key,
        )
      ) {
        // friggDetails.config is the config from integrations,
        // i.e. initial form data / user settings
        const incomingValue = this.friggDetails.config[input.key];
        if (input.inputType === 'select') {
          if (this.crossbeamDataSources[input.key]) {
            return this.filteredReportsOrPopulations(incomingValue, input.key);
          }
          return this.addNameToOption(incomingValue);
        }
        return incomingValue;
      }
      if (input.inputType === 'boolean' && !input.required) return false;
      return null;
    },
    labelText(required, label) {
      return required ? `${label} *` : label;
    },
    hasSomeHtml(description) {
      return /<\/?[a-z][\s\S]*>/i.test(description);
    },
    hideLabel(inputType) {
      return {
        boolean: true,
        checkbox: true,
        mapping: true,
      }[inputType];
    },
    filteredReportsOrPopulations(reportsOrPopulations, dataType) {
      const crossbeamData = this.crossbeamDataSources[dataType];
      if (this.fieldPayloadSpecs[dataType].multi) {
        return crossbeamData.reduce(
          (popsOrReportsArray, currentPopOrReport) => {
            if (
              reportsOrPopulations !== null &&
              Object.prototype.hasOwnProperty.call(
                reportsOrPopulations,
                currentPopOrReport.id,
              )
            ) {
              popsOrReportsArray.push(currentPopOrReport);
            }
            return popsOrReportsArray;
          },
          [],
        );
      }
      const [idToLookup] = Object.entries(reportsOrPopulations)[0];
      return crossbeamData.find((data) => data.id === idToLookup);
    },
    filteredSourcesAndTargets(sourcesAndTargetsArray, dataType, targetOptions) {
      if (Array.isArray(sourcesAndTargetsArray)) {
        // buildling up lookups to keep O(n)
        const crossbeamDataLookup = {};
        this.crossbeamDataSources[dataType].forEach((xbeamData) => {
          crossbeamDataLookup[xbeamData.id] = xbeamData;
        });
        const targetLookup = {};
        targetOptions.forEach((option) => {
          targetLookup[option.value] = option;
        });
        return sourcesAndTargetsArray.reduce(
          (finalArray, currentSourceAndTarget) => {
            const { source, target } = currentSourceAndTarget;
            const sourceAndTargetObj = {};
            if (crossbeamDataLookup[source.id] && targetLookup[target.value]) {
              sourceAndTargetObj.source = crossbeamDataLookup[source.id];
              sourceAndTargetObj.target = target;
              finalArray.push(sourceAndTargetObj);
            }
            return finalArray;
          },
          [],
        );
      }
    },
    filteredSourcesAndTargets2(
      sourcesAndTargetsArray,
      dataType,
      targetDataType,
    ) {
      if (Array.isArray(sourcesAndTargetsArray)) {
        // buildling up lookups to keep O(n)
        const crossbeamDataLookup = {}; // population_id : population
        this.crossbeamDataSources[dataType].forEach((xbeamData) => {
          crossbeamDataLookup[xbeamData.id] = xbeamData;
        });
        const targetLookup = {};
        this.crossbeamDataSources[targetDataType].forEach((xbeamData) => {
          targetLookup[xbeamData.id] = xbeamData;
        });
        return sourcesAndTargetsArray.reduce(
          (finalArray, currentSourceAndTarget) => {
            const { source, target } = currentSourceAndTarget;
            const sourceAndTargetObj = {};
            if (crossbeamDataLookup[source.id] && targetLookup[target.id]) {
              sourceAndTargetObj.source = crossbeamDataLookup[source.id];
              sourceAndTargetObj.target = targetLookup[target.id];
              finalArray.push(sourceAndTargetObj);
            }
            return finalArray;
          },
          [],
        );
      }
    },
    // There is a conflict with using "label" as the name of the field
    // with the Vue Multiselect plugin, it's already being used / reserved. This is why we
    // have to map a redundant property to the object, using name
    addNameToOption(options, type) {
      // reports already have a name property so we can just return "options"
      // note that options plural can be misleading... this can also be a single object
      // This is why we check for Array.isArray. If multiselect, it will be an array of objects,
      // otherwise it will be an object
      if (this.crossbeamDataSources[type]) return options;
      if (Array.isArray(options)) {
        return options.map((option) => ({ ...option, name: option.label }));
      }
      return { ...options, name: options.label };
    },
    checkValidTargetAndSource(source, target) {
      return (
        Object.keys(target).length === 0 && Object.keys(source).length === 0
      );
    },
    onSave() {
      // remove rows that have no target or source selected.
      // integration could hypothectially have many dataMaps so we need to
      // iterate through all "possibleDataMaps"
      const possibleDataMaps = this.formSetup.filter(
        (input) => input.inputType === 'mapping',
      );
      possibleDataMaps.forEach((dataMap) => {
        const idsToRemove = {};
        this.formValues[dataMap.key].forEach((mapping, idx) => {
          if (idx > 0) {
            if (
              this.checkValidTargetAndSource(mapping.source, mapping.target)
            ) {
              idsToRemove[idx] = true;
            }
          }
        });
        this.dataMaps[dataMap.key].mappingIds = this.dataMaps[
          dataMap.key
        ].mappingIds.filter((_id, idx) => !idsToRemove[idx]);
        this.formValues[dataMap.key] = this.formValues[dataMap.key].filter(
          (_option, idx) => !idsToRemove[idx],
        );
      });
      this.dataMaps = { ...this.dataMaps };
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$emit('saved', { inputsValid: false });
        return;
      }
      const payload = this.buildPayload();
      this.$emit('saved', { payload, inputsValid: true });
    },
    changeFriggOption(option, id) {
      this.v$.formValues[id].$model = option;
    },
    // If required, we are toggling between true and null so the error messages
    // get triggered. Otherwise we toggle between true and false "checked"
    changeFriggBooleanOption(checked, id, required) {
      if (!required) {
        this.changeFriggOption(checked, id);
      } else {
        if (checked) this.v$.formValues[id].$model = checked;
        else this.v$.formValues[id].$model = null;
      }
    },
    handleClearInput(id) {
      this.formValues[id] = null;
      this.v$.formValues[id].$touch();
    },
    buildPayload() {
      const payload = {};
      Object.entries(this.formValues).forEach(([id, originalVal]) => {
        const setup = this.fieldPayloadSpecs[id];
        if (setup.properties && originalVal !== null) {
          payload[id] = this.transformPayloadValue(originalVal, setup);
        } else if (setup.mapping) {
          payload[id] = this.transformMappingPayloadValue(originalVal, setup);
        } else {
          payload[id] = setup.type === 'Number' ? +originalVal : originalVal;
        }
      });
      return {
        id: this.friggDetails.id,
        config: payload,
      };
    },
    // Function below is based on the items object in setup which
    // tells us what keys are expected in the payload
    transformPayloadValue(originalObjOrArray, setup) {
      // Rare edge case of properties property being present but nothing inside of it
      if (!Object.keys(setup.properties).length) {
        return originalObjOrArray;
      }
      const [storeBy, storeByProps] = Object.entries(setup.properties)[0];
      // storeBy 'option.id'
      const [, entityId] = storeBy.split('.');
      if (setup.multi) {
        const transformedPayloadVal = originalObjOrArray.reduce((acc, curr) => {
          acc[curr[entityId]] = this.payloadKeyMapper(
            curr,
            storeByProps.properties,
          );
          return acc;
        }, {});
        return transformedPayloadVal;
      }
      return {
        [originalObjOrArray[entityId]]: this.payloadKeyMapper(
          originalObjOrArray,
          storeByProps.properties,
        ),
      };
    },
    payloadKeyMapper(originalObj, propertiesObj) {
      return Object.entries(propertiesObj).reduce(
        (acc, [prop, propReference]) => {
          const [, wantedProp] = propReference.split('.');
          acc[prop] = originalObj[wantedProp];
          return acc;
        },
        {},
      );
    },
  },
};
</script>

<style lang="pcss">
.c-frigg-form-builder {
  .c-frigg-form-builder__input-label {
    @apply text-sm text-neutral-600 flex items-center mb-8;
  }

  .c-frigg-form-builder__data-mapping .bitts-input-select__simple-label {
    max-width: 172px;
    @apply truncate;
  }

  .c-frigg-form-builder__input-description {
    @apply text-sm text-neutral-600 mt-8;

    a {
      @apply text-brand-blue underline;
    }
  }

  .c-frigg-form-builder__input {
    @apply mb-24;
  }

  ::placeholder {
    @apply text-neutral-400;
  }
  .data-mapping--source .bitts-input-select .multiselect__tags {
    border-right: 0 !important;
    border-radius: 6px 0 0 6px;
  }
  .data-mapping--target .bitts-input-select .multiselect__tags {
    border-radius: 0;
  }
  .data-mapping--remove {
    @apply flex items-center justify-center w-bts5 cursor-pointer
    rounded-r-md border-2 border-l-0 border-neutral-200;
  }

  .c-frigg-form-builder__data-mapping {
    .wrapper:not(:last-child) {
      @apply flex justify-between mb-8;
    }
    .multiselect__tags {
      @apply p-8 pb-0;
    }
  }
}
</style>
