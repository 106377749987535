<template>
  <div class="c-autocomplete relative inline-block">
    <BittsInput
      v-model="query"
      :placeholder="placeholder"
      :name="name"
      :disabled="disabled"
      :model-value="initialValue"
      :status="errors.length ? 'danger' : 'default'"
      :danger-text="errors?.at(-1)?.$message || ''"
    />
    <div
      v-if="showDropdown"
      v-click-away="closeDropdown"
      class="c-autocomplete__content"
    >
      <ul
        v-if="!loading && searchResults.length > 0"
        class="flex flex-col items-stretch"
      >
        <button
          v-for="(searchResult, index) in searchResults"
          ref="searchResult"
          :key="`searchResult_${index}`"
          class="c-autocomplete__results__item"
          @click="onItemSelected(searchResult)"
        >
          <slot :value="searchResult" name="item">
            {{ searchResult }}
          </slot>
        </button>
      </ul>
      <div v-if="loading" class="c-autocomplete__results--empty-state">
        <img
          class="c-autocomplete__searching-icon"
          src="/images/gifs/loading.gif"
        />
        Searching...
      </div>
    </div>
  </div>
</template>

<script>
import { BittsInput } from '@crossbeam/bitts';

import { get as lodashGet } from 'lodash';
import { mixin as VueClickAway } from 'vue3-click-away';

export default {
  name: 'AutoComplete',
  components: { BittsInput },
  mixins: [VueClickAway],
  props: {
    searchResults: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    lodashPath: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['dropdown-opened', 'dropdown-closed', 'change', 'selected'],
  data() {
    return { showDropdown: false, query: '' };
  },
  watch: {
    query(val) {
      this.onSearchChanged(val);
    },
  },
  methods: {
    openDropdown() {
      if (this.showDropdown) {
        return;
      }
      this.showDropdown = true;
      this.$emit('dropdown-opened');
    },
    closeDropdown() {
      if (!this.showDropdown) {
        return;
      }
      this.showDropdown = false;
      this.$emit('dropdown-closed');
    },
    scrollToIndex(index, block) {
      /* This is to ensure the element is visible. Seems like a lot of
      code for an unlikely edge case, but keeping for now */
      const element = this.$refs.searchResult?.[index];
      if (element) {
        element.$el.scrollIntoView({
          behavior: 'smooth',
          block,
          inline: 'center',
        });
      }
    },
    onItemSelected(searchResult) {
      this.closeDropdown();
      this.$emit('selected', searchResult);
      if (this.lodashPath) {
        this.query = lodashGet(searchResult, this.lodashPath);
      } else {
        this.query = searchResult;
      }
    },
    onSearchChanged(query) {
      this.openDropdown();
      this.$emit('change', query);
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-autocomplete {
  @apply w-full;
}

.c-autocomplete__content {
  @apply w-full bg-white shadow-overlay mt-2 rounded-bts-sm z-[2] absolute overflow-auto;
  max-height: 500px;
}

.c-autocomplete__searching-icon {
  @apply w-32;
}

.c-autocomplete__results--empty-state {
  @apply flex flex-col flex-1 items-center text-center justify-center;
  @apply text-brand-navy text-base min-h-96;
}

.c-autocomplete__results--empty-state:hover {
  cursor: pointer;
}

.c-autocomplete__results__item {
  @apply text-brand-navy;
  @apply text-base whitespace-nowrap items-center px-12 py-6;
}
</style>
