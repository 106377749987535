<template>
  <div>
    <div class="flex justify-center">
      <BittsSvg class="max-w-[204px] max-h-100" :svg="dataSource.type" />
    </div>
    <div class="my-24">
      <p v-if="isGoogleSheets" class="c-connect-form__subtitle">
        All sheets added will need to be owned by or shared with the account you
        authenticate.
      </p>
      <p v-else-if="isMicrosoftDynamics" class="c-connect-form__subtitle">
        To begin, you’ll need to connect to Microsoft Dynamics and choose the
        instance you’d like to use. Don't worry, you can always update these
        settings later.
      </p>
      <p v-else-if="dataSource.has_auth_step" class="c-connect-form__subtitle">
        To begin, you'll need to
        <span v-if="numRequiredFormVals > 0">
          provide the information below and then
        </span>
        go to {{ dataSource.friendly_name }} and log in to your account. Don't
        worry, you can always update these settings later.
      </p>
      <p v-else-if="isSnowflake" class="text-brand-navy">
        To add Snowflake as a data source in Crossbeam, you first need to share
        your data with us. If you need help doing this, check out
        <a
          class="underline text-info-text"
          href="https://help.crossbeam.com/en/articles/4470887-snowflake-integration"
          target="_blank"
          >our help article</a
        >.
      </p>
      <p v-else class="c-connect-form__subtitle">
        Please provide the information below to get started with
        {{ dataSource.friendly_name }}.
      </p>
    </div>
    <div
      v-for="(formProp, idx) in dataSource.form_properties"
      :key="'prop_' + formProp.name"
      :class="{
        'form-group--error': v$.formPropertyValues[formProp.name].$error,
      }"
    >
      <div
        v-if="formProp.json_schema.type === 'boolean'"
        class="form-group form-row"
      >
        <BittsCheckbox
          :label="formProp.friendly_label"
          @input="(checked) => handleCheck(formProp.name, checked)"
        />
      </div>
      <div
        v-else-if="formProp.json_schema.type === 'select'"
        class="form-group form-row"
      >
        <BittsDivider v-if="idx === 0" />
        <BittsSelect
          v-model="selected"
          :allow-clear="true"
          :disabled="disableRegionSelector"
          :placeholder="formProp.friendly_label"
          :options="
            formProp.options.map((option) => {
              return { ...option, label: option.name };
            })
          "
          :form-label="formProp.friendly_label"
          :searchable="false"
          @update:model-value="(val) => handleSelect(formProp.name, val)"
        />
      </div>
      <div
        v-else-if="formProp.json_schema.type === 'string'"
        class="form-group form-row mt-16"
      >
        <BittsInput
          v-model="formPropertyValues[formProp.name]"
          :form-label="{
            title: formProp.friendly_label,
            helpText: formProp.help_description,
          }"
          :data-testid="`data-source-${dataSource.type}-${formProp.name}`"
          :password="formProp.is_credential"
          name="data-source-snowflake-input"
          :placeholder="formProp.placeholder || formProp.friendly_label"
        />
      </div>
      <span
        v-if="!v$.formPropertyValues[formProp.name].required"
        class="form-group__message mt-8"
      >
        This field is required.
      </span>
      <span
        v-if="
          !v$.formPropertyValues[formProp.name].validation &&
          v$.formPropertyValues[formProp.name].required
        "
        class="form-group__message mt-8"
      >
        {{ 'Invalid ' + formProp.friendly_label }}
      </span>
    </div>
    <div
      v-if="dataSource.type === 'snowflake'"
      class="form-group form-row mt-16"
    >
      <BittsInput
        v-model="shareName"
        :form-label="{
          title: 'Share Name',
          helpText:
            'To add Snowflake as a data source in Crossbeam, you must share your data with Crossbeam via Snowflake Data Share.',
        }"
        placeholder=""
        name="share-name"
        class="c-connect-form__share-name"
      >
        <template #suffix>
          <BittsPopover
            :open="isShareNameCopied"
            :autoclose="1000"
            trigger="click"
            placement="top"
          >
            <span @click="copyShareName">
              <FontAwesomeIcon
                :icon="['far', 'copy']"
                :style="{
                  height: '14px',
                  width: '14px',
                  color: 'currentColor',
                }"
                class="text-neutral-300 cursor-pointer"
              />
            </span>
            <template #content>
              <div class="text-neutral-500 text-sm mb-4 px-4 py-6">
                {{ shareName }} copied to clipboard
              </div>
            </template>
          </BittsPopover>
        </template>
      </BittsInput>
    </div>
    <span v-if="v$.$error" class="form-group__message show p-2 mt-8">
      Please fix the issues above
    </span>
    <slot :submit="submit" name="submit-button" />
  </div>
</template>

<script>
import {
  BittsCheckbox,
  BittsDivider,
  BittsInput,
  BittsPopover,
  BittsSelect,
  BittsSvg,
} from '@crossbeam/bitts';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ref } from 'vue';

import useAuth from '@/composables/useAuth';
import {
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { copyToClipBoard } from '@/utils';

export default {
  components: {
    BittsInput,
    BittsDivider,
    BittsCheckbox,
    BittsPopover,
    BittsSelect,
    BittsSvg,
  },
  props: {
    dataSource: {
      type: Object,
      required: true,
    },
  },
  emits: ['saving-changed', 'post-snowflake-feed', 'redirect-retrieved'],
  setup(props) {
    const { currentOrg, currentUser } = useAuth();
    function createRegex(regex) {
      return function (str) {
        const globalRegex = RegExp(regex, 'g');
        return str ? globalRegex.test(str) : true;
      };
    }
    const formPropertyValues = ref({});
    const rules = {
      formPropertyValues: {
        ...props.dataSource.form_properties.reduce((obj, formProp) => {
          if (formProp.is_required && formProp.validation) {
            obj[formProp.name] = {
              required,
              validationFunction: createRegex(formProp.validation),
            };
          } else if (formProp.is_required) {
            obj[formProp.name] = { required };
          } else {
            obj[formProp.name] = {};
          }
          return obj;
        }, {}),
      },
    };
    const v$ = useVuelidate(rules, { formPropertyValues }, { $lazy: true });
    return { v$, formPropertyValues, currentOrg, currentUser };
  },
  data() {
    return {
      saving: false,
      selected: null,
      isShareNameCopied: false,
    };
  },
  computed: {
    numRequiredFormVals() {
      if (!this.dataSource) {
        return 0;
      }
      return this.dataSource.form_properties.filter(
        (formProp) => formProp.is_required,
      ).length;
    },
    shareName() {
      return `CROSSBEAM_SHARE_${this.currentOrg.id}`;
    },
    isSnowflake() {
      return this.dataSource.type === SNOWFLAKE_DATA_SOURCE_TYPE;
    },
    isGoogleSheets() {
      return this.dataSource.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE;
    },
    isMicrosoftDynamics() {
      return this.dataSource.type === MD_DATA_SOURCE_TYPE;
    },
  },
  created() {
    // pre-populate snowflake region
    if (this.dataSource.type === 'snowflake') {
      this.disableRegionSelector = false;
      const options = this.dataSource.form_properties.filter(
        (prop) => prop.name === 'region',
      )[0].options;
      if (options.length === 1) {
        this.disableRegionSelector = true;
      }
      this.selected = options[0].value;
      this.formPropertyValues.region = options[0].value;
    }
  },
  methods: {
    handleCheck(formPropName, checked) {
      this.formPropertyValues[formPropName] = checked;
    },
    handleSelect(formPropName, option) {
      this.selected = option ?? null;
      this.formPropertyValues[formPropName] = option ?? null;
    },
    copyShareName() {
      this.isShareNameCopied = true;
      setTimeout(() => {
        this.isShareNameCopied = false;
      }, 1000);
      copyToClipBoard(this.shareName);
    },
    getErrorMessage(formPropName) {
      return this.dataSource.form_properties.filter(
        (prop) => prop.name === formPropName,
      )[0].validation_message;
    },
    submit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.saving = true;
      this.$emit('saving-changed', this.saving);
      const payload = {
        integration_type: this.dataSource.type,
        form_properties: this.formPropertyValues,
      };
      if (this.dataSource.type === 'snowflake') {
        this.$emit('post-snowflake-feed', payload);
      } else {
        payload.settings_usecase = this.dataSource.settings_usecase;
        this.$emit('redirect-retrieved', payload);
      }
    },
  },
};
</script>

<style lang="pcss" scoped>
.c-connect-form__subtitle {
  @apply text-neutral-800 text-center;
}
</style>

<style lang="pcss">
.c-connect-form__share-name .c-bitts-input {
  @apply bg-neutral-background;
  .ant-input {
    @apply pointer-events-none bg-neutral-background;
  }
  .ant-input-affix-wrapper.c-bitts-input input {
    @apply bg-neutral-background opacity-80;
  }
}
.form-group.form-row {
  .bitts-select {
    @apply flex flex-col;
  }
}
.form-group__message {
  display: none;
  color: var(--theme-red);
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  margin-top: 8px;
}

.form-group__message.show {
  display: block;
}

.form-group--error .form-group__message {
  display: block;
}
</style>
