import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { captureException } from '@/errors';
import { ls } from '@/local_storage';
import { initStore } from '@/stores/store-utils';
import { EmptyObject } from '@/types/common';
import {
  OfflinePartner,
  OldSentPartnershipProposal,
  OverlapCounts,
  Partner,
  PartnerTag,
  PartnerTagMap,
  PublicInviteOrg,
  ReceivedPartnershipProposal,
  SentPartnershipProposal,
  isNewProposal,
  isOfflinePartner,
} from '@/types/partners';
import urls from '@/urls';

export const usePartnersStore = defineStore('PartnersStore', () => {
  const partnerOrgs = ref<Partner[]>([]);
  const offlinePartners = ref<OfflinePartner[]>([]);
  const partnerOrgsLookup = ref<Record<string, Partner | OfflinePartner>>({});
  const proposalsSent = ref<SentPartnershipProposal[]>([]);
  const proposalsReceived = ref<ReceivedPartnershipProposal[]>([]);
  const overlapCounts = ref<OverlapCounts | EmptyObject>({});
  const partnersByTag = ref<PartnerTagMap>({});
  const partnerTags = ref<PartnerTag[]>([]);
  const partnerFilters = ref<Record<string, boolean>>({});
  const publicInviteOrg = ref<PublicInviteOrg>(null);
  const publicInviteCode = ref<string>('');

  const { error, ready, readySync, running, refresh } = initStore(
    async (opts = { useCache: false }) => {
      try {
        const publicInvite: { code: string; org: null } = ls.publicInvite.get();
        if (publicInvite) {
          setPublicInviteCode(publicInvite.code);
          setPublicInviteOrg(publicInvite.org);
        }

        const [partnersResponse, partnerTagsResponse] = await Promise.all([
          axios.get<{
            proposals: (SentPartnershipProposal | OldSentPartnershipProposal)[];
            proposals_received: ReceivedPartnershipProposal[];
            partner_orgs: (Partner | OfflinePartner)[];
          }>(urls.partners.all),
          axios.get<{ items: PartnerTag[] }>(urls.partnerTags.all),
        ]);

        // Only call this on store initialization, not on refreshes...
        if (opts.useCache) await refreshOverlapUsage();

        partnerTags.value = partnerTagsResponse.data.items;
        partnerTagsResponse.data.items.forEach((tag) => {
          if (!partnerFilters.value[tag.id]) {
            partnerFilters.value[tag.id] = false;
          }
        });

        // Set partners
        partnerOrgs.value = partnersResponse.data.partner_orgs.sort();
        offlinePartners.value =
          partnersResponse.data.partner_orgs.filter(isOfflinePartner);

        proposalsSent.value = partnersResponse.data.proposals.map(
          formatOldStyleProposal,
        );

        proposalsReceived.value = partnersResponse.data.proposals_received;
        partnerOrgsLookup.value = partnerOrgs.value.reduce<
          Record<number, Partner | OfflinePartner>
        >((orgMap, org) => {
          orgMap[org.id] = org;
          return orgMap;
        }, {});

        partnersByTag.value = partnerOrgs.value.reduce<
          Record<string, number[]>
        >((tagLookup, org) => {
          if (!org.tags) return {};
          org.tags.forEach((tag) => {
            const tagId = tag.id;
            if (!tagLookup[tagId]) tagLookup[tagId] = [];
            tagLookup[tagId].push(org.id);
          });
          return tagLookup;
        }, {});
      } catch (err) {
        captureException(err);
      }
    },
  );

  refresh({ useCache: true });

  // Methods
  function getPartnerOrgById(id: number, trimmed = true) {
    const partnerOrg = partnerOrgsLookup.value[id];
    if (!partnerOrg) return;
    return trimmed ? trimOrg(partnerOrg) : partnerOrg;
  }

  function getPartnerOrgByUuid(uuid: string, trimmed = true) {
    const partnerOrg = partnerOrgs.value.find(
      (partner) => partner.uuid === uuid,
    );
    if (!partnerOrg) return;
    return trimmed ? trimOrg(partnerOrg) : partnerOrg;
  }

  function getPartnersByTag(tagId: string) {
    return partnersByTag.value[tagId] || [];
  }

  function getPartnerTagById(id: string) {
    return partnerTags.value.find((tag) => tag.id === id);
  }

  function getProposalReceivedById(id: number) {
    return proposalsReceived.value.find((proposal) => proposal.id === id);
  }

  function getProposalSentById(id: number) {
    return proposalsSent.value.find((proposal) => proposal.id === id);
  }

  function addProposal(proposal: SentPartnershipProposal) {
    const proposalIds = proposalsSent.value.map((proposal) => proposal.id);
    if (!proposalIds.includes(proposal.id)) {
      proposalsSent.value.push(formatOldStyleProposal(proposal));
    }
  }

  async function refreshOverlapUsage() {
    const usageResponse = await axios.get<OverlapCounts>(
      urls.partners.overlaps,
    );
    overlapCounts.value = usageResponse.data;
  }

  function setPublicInviteOrg(org: null) {
    publicInviteOrg.value = org;
  }

  function setPublicInviteCode(code: string) {
    publicInviteCode.value = code;
  }

  function updateFilters(tagId: string) {
    partnerFilters.value[tagId] = !partnerFilters.value[tagId];
  }

  function resetPartnerFilters() {
    Object.keys(partnerFilters.value).forEach((tag) => {
      partnerFilters.value[tag] = false;
    });
  }

  async function deleteTag(tagId: string) {
    await axios.delete(urls.partnerTags.delete(tagId));
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete partnerFilters.value[tagId];
    refresh();
  }

  async function revokeProposal(proposalId: number) {
    try {
      await axios.put(urls.proposals.remove(proposalId));
    } catch (err) {
      captureException(err);
    } finally {
      const newProposals = [...proposalsSent.value];
      proposalsSent.value = newProposals.filter(
        (proposal) => proposal.id !== proposalId,
      );
      refresh();
    }
  }

  async function declineProposal(proposalId: number) {
    try {
      await axios.put(urls.proposals.decline(proposalId));
    } catch (err) {
      captureException(err);
    } finally {
      const newProposals = [...proposalsReceived.value];
      proposalsReceived.value = newProposals.filter(
        (proposal) => proposal.id !== proposalId,
      );
      refresh();
    }
  }

  async function refreshProposals() {
    try {
      const { data } = await axios.get<{
        items: ReceivedPartnershipProposal[];
      }>(urls.partners.proposalsReceived);
      proposalsReceived.value = data.items;
    } catch (err) {
      captureException(err);
    }
  }

  const openDealsTotal = computed(
    () => overlapCounts.value?.overlap_usage?.total_open_deal_amount,
  );

  const hasPartners = computed(() => !!partnerOrgs.value.length);

  return {
    error,
    ready,
    readySync,
    running,
    refreshPartnersStore: refresh,
    partnerOrgs,
    offlinePartners,
    partnerOrgsLookup,
    proposalsSent,
    proposalsReceived,
    overlapCounts,
    partnersByTag,
    partnerTags,
    partnerFilters,
    publicInviteOrg,
    publicInviteCode,
    getPartnerOrgById,
    getPartnerOrgByUuid,
    getPartnersByTag,
    getPartnerTagById,
    getProposalReceivedById,
    getProposalSentById,
    openDealsTotal,
    hasPartners,
    addProposal,
    setPublicInviteOrg,
    setPublicInviteCode,
    updateFilters,
    resetPartnerFilters,
    deleteTag,
    revokeProposal,
    declineProposal,
    refreshProposals,
    refreshOverlapUsage,
  };
});

// Converts old schema for partnership proposals to new data schema
function formatOldStyleProposal(
  proposal: OldSentPartnershipProposal | SentPartnershipProposal,
): SentPartnershipProposal {
  if (isNewProposal(proposal)) {
    return proposal;
  }
  return {
    id: proposal.id,
    status: proposal.status,
    created_at: proposal.created_at,
    contact_name: proposal.contact.name,
    contact_email: proposal.contact.email,
    contact_company: proposal.contact.company,
    sending_user: proposal.sending_user,
  };
}

export const trimOrg = (org: Partner | OfflinePartner) => {
  if (!org) return undefined;
  const trimmedOrg = { ...org };
  delete trimmedOrg.users;
  delete trimmedOrg.tags;
  return trimmedOrg;
};
